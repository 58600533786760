import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function Vienna() {
  return (
    <Layout>
      <Helmet title="Vienna | Billy Joel" />

      <h1>Vienna</h1>

      <h2>Billy Joel</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Slow down you crazy child</p>
          <p>You're so ambitious for a juvenile</p>
          <p>But then if you're so smart tell me,</p>
          <p>Why are you still so afraid? (mmmmm)</p>
        </Verse>
        <Verse>
          <p>Where's the fire, what's the hurry about?</p>
          <p>You better cool it off before you burn it out</p>
          <p>You got so much to do and only</p>
          <p>So many hours in a day (Ay)</p>
        </Verse>
        <Chorus>
          <p>But you know that when the truth is told</p>
          <p>That you can get what you want</p>
          <p>Or you can just get old</p>
          <p>
            You're gonna kick off before you even get halfway through (Oooh)
          </p>
          <p>When will you realize... Vienna waits for you?</p>
        </Chorus>
        <Verse>
          <p>Slow down you're doing fine</p>
          <p>You can't be everything you want to be before your time</p>
          <p>Although it's so romantic on the borderline tonight (tonight)</p>
        </Verse>
        <Verse>
          <p>Too bad, but it's the life you lead</p>
          <p>You're so ahead of yourself that you forgot what you need</p>
          <p>Though you can see when you're wrong</p>
          <p>You know you can't always see when you're right (you're right)</p>
        </Verse>
        <Chorus>
          <p>You got your passion, you got your pride</p>
          <p>But don't you know that only fools are satisfied?</p>
          <p>Dream on, but don't imagine they'll all come true (Oooh)</p>
          <p>When will you realize... Vienna waits for you?</p>
        </Chorus>
        <Verse>
          <p>CHORDS</p>
        </Verse>
        <Chorus>
          <p>Slow down you crazy child</p>
          <p>Take the phone off the hook and disappear for a while</p>
          <p>It's alright, you can afford to lose a day or two (oooh)</p>
          <p>When will you realize... Vienna waits for you?</p>
        </Chorus>
        <Chorus>
          <p>And you know that when the truth is told</p>
          <p>That you can get what you want or you can just get old</p>
          <p>
            You're gonna kick off before you even get halfway through (oooh)
          </p>
          <p>Why don't you realize... Vienna waits for you?</p>
          <p>When will you realize... Vienna waits for you?</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
